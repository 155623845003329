import React, {useState, useEffect} from 'react'
import login from '../images/login.png'
import logo from '../images/logo.png'
import fb from '../images/fb.png'
import google from '../images/google.png'
import twitter from '../images/twitter.png'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import axios from 'axios'
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult } from "firebase/auth";
import {RotatingTriangles} from  'react-loader-spinner'
import {app} from '../firebase'
const Login = () => {


  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [loader, setLoader] = useState(false)
  const [language, setLanguage] = useState('')
  const [open, setOpen] = useState(false)
  const provider = new GoogleAuthProvider();

  const location= useLocation()
  const loc = location?.state
  
  useEffect(()=>{
    if(loc){
      if(loc.op == "robi" && !localStorage.getItem('userId')){
        localStorage.setItem('op', 'robi')
        handleGuest()
      }
      else if(loc.op == "oore" && !localStorage.getItem('userId')){
        localStorage.setItem('op', 'oore')
        handleGuest()
      }
      else if(loc.op == "orange" && !localStorage.getItem('userId')){
        localStorage.setItem('op', 'orange')
        handleGuest()
      }
    }
  },[loc])


  const auth = getAuth(app);
  auth.languageCode = 'it';

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let query = useQuery()

console.log(query.get('msisdn'))


  useEffect(()=>{
    setLoader(true)
    if(localStorage.getItem('language')){
      setLanguage(localStorage.getItem('language'))
    }
    else{
      setLanguage('english')
    }


    if(localStorage.getItem('userId')){
      navigate('/home')
    }
    else{

      getRedirectResult(auth)
    .then((result) => {
      setLoader(true)
      
      // This gives you a Google Access Token. You can use it to access the Google API.
      console.log(result)
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log(user)
      console.log('Token: '+ token)
      axios.post('https://highfivesgames.com/users/sociallogin',{
        type:'google',
        name:user.displayName,
        email:user.email,
        uid:user.uid,
      },{
        headers:{
          'x-operator':query.get('op')
      }
      })
      .then((response)=>{
        if(response.status !=200){
          axios.post('https://highfivesgames.com/users/socialReg',{
            type:'google',
            name:user.displayName,
            email:user.email,
            uid:user.uid,
          },{
            headers:{
              'x-operator':query.get('op')
          }
          }).then((response)=>{
            console.log(response);
            localStorage.setItem('userId',response.data.id)
            navigate('/home')
          })
        }
        else{
          console.log(response)
          // setLoader(false)
          localStorage.setItem('userId',response.data.user.id)
          localStorage.setItem('sessionId',response.data.user.session)
          navigate('/home')
        }
      })
      .catch((err)=>{
        console.log(err)
      })
    }).catch((error) => {
      setLoader(false)
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(error)
      // ...
    });
    }
  },[])

  const googleLogin =  (e)=>{
    e.preventDefault();
     signInWithRedirect(auth, provider)
     setLoader(true);
  }


  const handleGuest = (e) => {
    // e.preventDefault()
    axios.get('https://highfivesgames.com/users/guestlogin',{
      headers:{
        'x-operator':query.get('op')
    }
    })
    .then((response) =>{
      // console.log(response.user.id)
      localStorage.setItem('userId',response.data.user.id)
      localStorage.setItem('sessionId',response.data.user.session)
      navigate('/home')
    })
    .catch((err)=>{console.log(err)})
  }

  axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (403 === error.response.status) {
        // handle error: inform user, go to login, etc
        alert('Invalid email or password')
    } else {
        return Promise.reject(error);
    }
});


  const handleSubmit=(e)=>{
    e.preventDefault()
    if(email && pass){
      console.log(email,pass)
      const string = btoa(`${email}:${pass}`)
      const fstring = 'Basic '+ string;
      console.log(fstring)
      axios.post('https://highfivesgames.com/users/login',{},{
        headers:{
          'authorization':fstring,
          'x-operator':query.get('op')
        
        },
      })
      .then((response)=>{
        console.log(response)
        if(response.status === 200){
          navigate('/home')
          localStorage.setItem('userId',response.data.user.id)
          localStorage.setItem('sessionId',response.data.user.session)
        }
        setEmail('')
        setPass('')
      })
      .catch((error)=>{
        console.log(error)
        setEmail('')
        setPass('')
      })
    }
    else {
      alert('Empty email or password')
    }
  }
  return (
    <div className="font-poppins max-w-[30rem]  w-screen mx-auto o h-screen  bg-[#2C3F66]">
      {
        loader ?
        <div className="">
                    <div className="flex h-screen w-full justify-center items-center">
                    <RotatingTriangles></RotatingTriangles>
                    </div>
        </div>
        :
        <div>
          <div className="flex justify-between items-center px-2">
          <img src={logo} className="h-16  pt-2"></img>
          <div className="text-white mr-5 relative">
            <button onClick={()=>{setOpen(!open)}}>
            Language : {language}
            </button>
            {
              open &&
            <div className="flex flex-col bg-white text-black px-3 py-2 w-full rounded-xl absolute">
              <button onClick={()=>{
                setLanguage('arabic')
                localStorage.setItem('language','arabic')
              }}>
                <p>عربي</p>
                </button>
                <button onClick={()=>{
                setLanguage('english')
                localStorage.setItem('language','english')
              }}>
                <p>English</p>
                </button>
            </div>
            }
          </div>
          </div>
        <img src={login} className="mx-auto h-[18rem] -mt-8"></img>
        <div className="bg-[#FF6356] -pb-32 overflow- h-[30rem] -z-10 -skew-y-[18deg] mt-16 ">
          <div className="bg-white rounded-3xl shadow-3xl skew-y-[18deg] -mt-12 px-6 py-4 mx-6">
            <h1 className="text-[#2C3F66] text-center ">
              {
                language == 'english'
                ?
              'Sign in to your account'
              :
              'تسجيل الدخول إلى حسابك'

              }</h1>
            <form className="mt-6">
              <label className="mt-1">
                {
                language == 'english'?  
                'Your Email'
                :
                'بريدك الالكتروني'
                }
                </label><br></br>
              <input className="my-2 w-full border-b-2 outline-none pb-1 " type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}></input><br></br>
              <label className="mt-2">
              {
                language == 'english'?  
                'Password'
                :
                'كلمة المرور'
                }
                </label><br></br>
              <input className="my-2 w-full border-b-2 outline-none pb-1" type="password" value={pass} onChange={(e)=>{setPass(e.target.value)}}></input>
              <h1 className="text-right text-[#1991EB] mb-3 text-sm">
              {
                language == 'english'?  
                'Forgot Password?'
                :
                'هل نسيت كلمة السر؟'
                }
              </h1>
              <div className="flex justify-between">
                <div className="flex space-x-4">
                  <button onClick={googleLogin}>
                    <img src={google} ></img>
                    </button>
                </div>
                <button onClick={handleSubmit} className="bg-[#2C3F66] text-white px-10 -mr-6 -mb-4 rounded-l-3xl rounded-br-3xl "> 
                {
                language == 'english'?  
                'Sign in'
                :
                'تسجيل الدخول'
                }
                </button>
                
              </div>
            </form>
          </div>
          <div className="skew-y-[18deg] mt-4 text-white text-center">
          <button   onClick={handleGuest}>
          {
                language == 'english'?  
                'Login as a Guest'
                :
                'تسجيل الدخول كضيف'
                }
          </button>
          </div>
            <Link to="/signup" className="">
            <h1 className="text-center mt-8 text-white skew-y-[18deg] ">
            {
                language == 'english'?  
                "Don't have an account? Sign up"
                :
                'ليس لديك حساب؟ اشترك'
                }
            </h1>
            </Link>
        </div>
        <div className="w-screen max-w-[30rem] py-20 -mt-20 bg-[#FF6356]"></div>
        </div>
        
      }
        
    </div>
  )
}

export default Login